import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import { PALETTE } from '@/themes'
import { marginProps, paddingProps } from '@/ui/SpacingUtility'

const FlexWrapper = styled(Wrapper)`
    ${paddingProps};
    ${marginProps};
    display: flex;
    background-color: ${PALETTE.primary};
    flex-flow: ${(props) => (props.direction ? props.direction : 'column')};
    justify-content: ${(props) => (props.align ? props.align : 'center')};
    height: ${(props) => (props.height ? props.height : '100%')};
    position: relative;
    align-items: ${(props) => (props.verticalAlign ? props.verticalAlign : '')};
    width: ${(props) => (props.width ? props.width : '100%')};
`

export default FlexWrapper
