import React, { useState } from 'react'
import Button from 'ui/controls/Button'
import moment from 'moment'
import FlexWrapper from 'ui/FlexWrapper'
import Wrapper from 'ui/Wrapper'
import Text from 'ui/Text'
import Arrow from 'ui/Arrow'
import Box from 'ui/Box'
import DatePicker from 'features/DatePicker'
import { byPlatform } from 'common/adapters/DeviceDetectAdapter'

const t = window.T

const ChooseDate = ({
    uiDateFormat,
    fromDate,
    toDate,
    showData,
    setFromDate,
    setToDate,
}) => {
    const [datePicker, setDatePicker] = useState(false)
    const [selectedMode, setSelectedMode] = useState('')
    const [selectedDate, setSelectedDate] = useState(null)

    const openDatePicker = (mode) => {
        let selectedDate = mode === 'dateFrom' ? fromDate : toDate
        setSelectedDate(selectedDate)
        setSelectedMode(mode)
        setDatePicker(true)
    }

    const onDateSelected = (mode, date) => {
        if (mode === 'dateFrom') {
            setFromDate(date)
            setDatePicker(false)
        } else {
            setToDate(date)
            setDatePicker(false)
        }
    }
    return (
        <Box>
            {datePicker ? (
                <DatePicker
                    maxDate={new Date()}
                    height={byPlatform(null, 445)}
                    width="100%"
                    onDateSelected={onDateSelected.bind(this, selectedMode)}
                    selectedDate={selectedDate}
                />
            ) : (
                <Wrapper>
                    <Text>{t.transactions.showFrom}</Text>
                    <Button
                        onClick={openDatePicker.bind(this, 'dateFrom')}
                        variant="light"
                        width="100%"
                        block
                        mt={2}
                    >
                        {fromDate ? (
                            <Text align="center">
                                {moment(fromDate).format(uiDateFormat)}
                            </Text>
                        ) : (
                            <FlexWrapper
                                padding="0px"
                                direction="row"
                                align="space-around"
                            >
                                <Text>showFrom</Text>
                                <Arrow
                                    block
                                    direction="down"
                                    margin="12px 10px 0px"
                                />
                            </FlexWrapper>
                        )}
                    </Button>
                    <Text mt={4}>{t.transactions.showTo}</Text>
                    <Button
                        onClick={openDatePicker.bind(this, 'dateTo')}
                        variant="light"
                        width="100%"
                        block
                        mt={2}
                    >
                        {toDate ? (
                            <Text align="center">
                                {moment(toDate).format(uiDateFormat)}
                            </Text>
                        ) : (
                            <FlexWrapper
                                padding="0px"
                                direction="row"
                                align="space-around"
                            >
                                <Text>showTo</Text>
                                <Arrow
                                    block
                                    direction="down"
                                    margin="12px 10px 0px"
                                />
                            </FlexWrapper>
                        )}
                    </Button>

                    <Button
                        block
                        size={'lgB'}
                        accent
                        mt={5}
                        width="100%"
                        onClick={() => showData()}
                    >
                        {t.transactions.chooseDate}
                    </Button>
                </Wrapper>
            )}
        </Box>
    )
}

export default ChooseDate
