import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import Box from 'ui/Box'
import Text from 'ui/Text'
import { BACKGROUNDS } from 'themes'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { numberWithCommasAndDots } from 'utils/formatters'

const GroupWrapper = styled.div`
    margin: 0 auto 2rem;
`
const GroupItems = styled.div`
    background: white;
    box-shadow: 0 0 6px 3px #00000014;
    border-radius: 4px;
`
const GroupItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    ${({ border }) => border && 'border-bottom: 1px solid #0000000a'};
`

const TransactionList = ({
    data,
    getType,
    theme,
    groupBy = 'MMMM',
    prefix = '',
    periodTitle = [],
    listType,
}) => {
    const [sortedData, setSortedData] = useState(null)

    useEffect(
        () => {
            if (periodTitle.length) {
                const fromDate = moment(periodTitle[0]).format('DD. MMMM YYYY')
                const toDate = moment(periodTitle[1]).format('DD. MMMM YYYY')
                setSortedData({
                    [`${fromDate} - ${toDate}`]: data,
                })
            } else {
                datesGroupByPeriod(data, groupBy)
            }
        },
        [groupBy, data]
    )

    const dateFormatter = (data) => {
        const date = data.split(' ')[0].split('.')
        const time = data.split(' ')[1]
        const formattedDate = `${date[0]}/${date[1]}-${date[2]} kl.`
        return `${formattedDate} ${time}`
    }

    const datesGroupByPeriod = (dates, token) => {
        const sorted = dates.sort(
            (a, b) =>
                moment(b.transactionDate).format('YYYYMMDD') -
                moment(a.transactionDate).format('YYYYMMDD')
        )
        return sorted.reduce((val, obj) => {
            const date = new Date(obj.transactionDate)
            let comp = moment(date, 'MM/DD/YYYY')
                .locale('da')
                .format(token)

            if (token === 'DD') {
                const month = moment(date, 'MM/DD/YYYY')
                    .locale('da')
                    .format('MMMM')
                comp = `${comp}. ${month}`
            }
            if (prefix) {
                comp = `${prefix} ${comp}`
            }

            if (val[comp]) {
                val[comp].push(obj)
            } else {
                val[comp] = [obj]
            }

            setSortedData(val)
            return val
        }, {})
    }

    const groupItem = (transaction) => {
        return (
            <Fragment>
                <Box>
                    <Text size={'md'} color={BACKGROUNDS.primary} mb={1}>
                        {getType(transaction)}
                    </Text>
                    <Text size={'sm'} color={BACKGROUNDS.greyTransparent}>
                        {dateFormatter(
                            new Date(
                                transaction.transactionDate
                            ).toLocaleString('da-DK', {
                                dateStyle: 'short',
                                timeStyle: 'short',
                            })
                        )}
                    </Text>
                </Box>
                <Box>
                    <Text
                        size={'md'}
                        align={'right'}
                        mb={1}
                        color={
                            transaction.amount >= 0 ? theme.jade : theme.dark
                        }
                    >
                        {numberWithCommasAndDots(transaction.amount)}
                    </Text>
                    <Text
                        size={'sm'}
                        align={'right'}
                        color={BACKGROUNDS.greyTransparent}
                    >
                        ID: {transaction.transId}
                    </Text>
                </Box>
            </Fragment>
        )
    }

    return (
        <Box style={{ paddingBottom: listType === 'deposit' ? '80%' : '50%' }}>
            {sortedData &&
                Object.keys(sortedData).map((key, index) => (
                    <GroupWrapper key={index}>
                        <Text
                            bold
                            size={'sm'}
                            color={BACKGROUNDS.primary}
                            mb={2}
                        >
                            {key.toUpperCase()}
                        </Text>
                        <GroupItems>
                            {sortedData[key]
                                .sort(
                                    (a, b) =>
                                        new Date(b.transactionDate) -
                                        new Date(a.transactionDate)
                                )
                                .map((transaction, index) => (
                                    <GroupItem
                                        key={index}
                                        border={
                                            index < sortedData[key].length - 1
                                        }
                                    >
                                        {groupItem(transaction)}
                                    </GroupItem>
                                ))}
                        </GroupItems>
                    </GroupWrapper>
                ))}
        </Box>
    )
}

TransactionList.propTypes = {
    data: PropTypes.array.isRequired,
    getType: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    groupBy: PropTypes.string,
    prefix: PropTypes.string,
    periodTitle: PropTypes.array,
    listType: PropTypes.string,
}

export default TransactionList
