import React, { lazy, useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import { CASINO_CATEGORIES, SMART_FILTERS } from 'configs/categories'
import ButtonsSwitcher from 'features/GamesList/ButtonsSwitcher'
import WithGames from 'common/Hocs/WithGames'
import BANNER_FLOW from 'configs/banner-flow'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { renderLoader } from 'ui/RenderLoader'
import { modalService } from 'services/ModalService'
import FilterToggler from 'features/SmartFilters/Components/FilterToggler'
import SmartFilterService from 'services/SmartFilterService'
import SortService from 'services/SmartSortService'
import FilterGamesService from 'services/FilterGamesService'
import sortArrows from 'icons/basic-icons/sortArrows.svg'
import GamesService from 'services/GamesService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import ContentService from 'services/ContentService'
import CasinoPageContent from 'scenes/CasinoPageContent'
import COLORS from 'themes/primary/palette'
import { PROVIDERS } from 'common/providers'
import { sortingByHotClicks } from 'utils/sorting'

import HighlightSlider from 'features/Highlight/HighlightSlider'

//const HighlightSlider = lazy(() => import('features/Highlight/HighlightSlider'))

const t = window.T

const Line = styled.hr`
    background: ${COLORS.background};
    width: 100%;
    height: 1px;
    border: none;
`

const StyledPageContent = styled(PageContent)`
    padding: 0 1rem 1rem;
`

const FilterWrapper = styled(Flex)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    margin-top: 1rem;

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        float: right;
        margin-top: 10px;
    }
`

const CasinoPage = ({ theme, gamesToMap = [], isGamesReceived }) => {
    const history = useHistory()
    const match = useRouteMatch()
    const [displayedGames, setDisplayedGames] = useState([])
    const [popularGames, setPopularGames] = useState([])
    const [jackpotGames, setJackpotGames] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [selectedFilters, setSelectedFilters] = useState([])
    const [selectedSorts, setSelectedSorts] = useState([])
    const [activeCategory, setActiveCategory] = useState({})
    const [subCategory, setSubCategory] = useState('')
    const [banner, setBanner] = useState('')
    const [highlights, setHighlightsList] = useState([])

    const setUrlByCategory = (category) => {
        history.push(`/${t.pages.casino}/${category}`)
    }

    const setSmartFilters = (filters) => {
        SmartFilterService.setFilters(filters)
        setSelectedFilters(filters)
    }

    const categoriesWithItemCount = () => {
        return CASINO_CATEGORIES.map((el, key) => {
            let count = 0
            switch (el?.name) {
                case 'popular':
                    count = popularGames.length
                    break
                case 'providers':
                    count = PROVIDERS.length
                    break
                case 'categories':
                    count = SMART_FILTERS.filter(
                        (item) =>
                            item.category === 'features' ||
                            item.category === 'theme'
                    ).length
                    break
                default:
                    count = filterGamesByCategory(gamesToMap, el?.name).length
            }

            return { ...el, ...{ count } }
        })
    }

    const setSmartSortFilters = (sorFilters) => {
        SortService.setSorts(sorFilters)
        setSelectedSorts(sorFilters)
    }

    const onChangeTabHandler = (tab) => {
        setUrlByCategory(tab.url)
    }

    const filterGamesByCategory = (games, urlCategory) => {
        return games.filter(
            (game) =>
                game.casinoPageCategories.includes(urlCategory) &&
                game.category.includes('slots')
        )
    }

    const showSmartFilters = () => {
        modalService.showModal('SMART_FILTERS', {
            initialProps: {
                animation: true,
                outsideClose: true,
            },
        })
    }

    const showSortModal = () => {
        modalService.showModal('SORTER', {
            initialProps: {
                animation: true,
                outsideClose: true,
            },
        })
    }

    const setBannerFlowLink = (category) => {
        if (category === 'jackpot') {
            return BANNER_FLOW.jackpot
        } else {
            return BANNER_FLOW.automater
        }
    }

    const showNoGamesLabel = () => {
        const filterByCategory = ['categories', 'providers']
        return match.params.category.indexOf(filterByCategory) > -1
    }

    const getCategoryName = (category) =>
        CASINO_CATEGORIES.find((c) => c.url === category)

    FilterGamesService.setFilteredGames(displayedGames.length)

    const sortByCategory = (category) => {
        const games = filterGamesByCategory(gamesToMap, category?.name)

        switch (category?.name) {
            case 'all':
                return games.sort((a, b) => b.clicks - a.clicks)
            case 'bonus':
                return games.sort((a, b) => b.clicks - a.clicks)
            case 'jackpot':
                return games.sort(
                    (a, b) =>
                        parseFloat(b.jackpot_amount) -
                            parseFloat(a.jackpot_amount) ||
                        a.name.localeCompare(b.name)
                )
            case 'new':
                return games.sort((a, b) => a.priority - b.priority)
            case 'categories':
            case 'exclusive':
            case 'providers':
                return games
        }
    }

    useEffect(
        () => {
            setShowLoader(true)

            const category = getCategoryName(match.params.category)
            if (category && Object.keys(category)) {
                setActiveCategory(category)

                if (category?.name === 'all') {
                    if (!selectedFilters.length) {
                        setDisplayedGames(sortByCategory(category))
                        setTimeout(() => setShowLoader(false), 1000)
                    }
                } else if (category?.name === 'popular') {
                    setDisplayedGames(sortingByHotClicks(popularGames))
                    setTimeout(() => setShowLoader(false), 1000)
                } else {
                    setDisplayedGames(sortByCategory(category))
                    setTimeout(() => setShowLoader(false), 1000)
                }
            } else {
                history.push('/404')
            }
        },
        [gamesToMap, match.params.category, popularGames]
    )

    useEffect(() => {
        GamesService.getPopularGames({ rodHot: true })
            .then((res) => {
                if (res.length) {
                    setShowLoader(false)
                    setPopularGames(res)
                }
            })
            .catch((e) => console.error(e))

        const sub = ContentService.getCampaignsList().subscribe((res) => {
            const highlights = res.filter((el) => el.highlight === 'true')
            setHighlightsList(highlights)
        })

        return () => {
            sub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            const SmartFilterSub = SmartFilterService.getFilters().subscribe(
                (filters) => {
                    setSelectedFilters(filters)
                }
            )

            const SortFilterSub = SortService.getSorts().subscribe((sorts) =>
                setSelectedSorts(sorts)
            )

            return () => {
                setSmartFilters([])
                setSmartSortFilters([])

                SmartFilterSub.unsubscribe()
                SortFilterSub.unsubscribe()
            }
        },
        [match.params.category]
    )

    useEffect(
        () => {
            const categoryParams = getCategoryName(match.params.category)

            if (categoryParams?.name === 'all') {
                /**
                 * Applies both filtering and sorting for 'alle' category
                 */
                let automatGames = filterGamesByCategory(
                    selectedSorts.length
                        ? gamesToMap
                        : sortByCategory(categoryParams),
                    categoryParams?.name
                )

                const filteredSortedGames = SortService.sortGames(
                    SmartFilterService.filterGames(
                        automatGames,
                        selectedFilters
                    ),
                    selectedSorts
                )

                setDisplayedGames(filteredSortedGames)
            }
        },
        [selectedFilters, selectedSorts, gamesToMap]
    )

    return (
        <PageWrapper>
            <HighlightSlider
                theme={theme}
                highlights={highlights}
                loop={detectMobile() ? false : true}
            />
            <PageContent p={3} maxWidth={'1140px'}>
                <ButtonsSwitcher
                    activeTab={
                        match.params?.subCategory ? null : activeCategory?.name
                    }
                    onChange={onChangeTabHandler}
                    tabs={categoriesWithItemCount()}
                />
            </PageContent>
            <Line />
            <StyledPageContent p={3} maxWidth={'1140px'}>
                {match.params.category === t.casinoCategories.all && (
                    <>
                        <FilterWrapper>
                            <FilterToggler
                                active={selectedFilters.length > 0}
                                onAction={showSmartFilters}
                                onCancel={() =>
                                    SmartFilterService.setFilters([])
                                }
                            >
                                Smart filter
                            </FilterToggler>
                            <FilterToggler
                                onAction={showSortModal}
                                icon={sortArrows}
                            >
                                {selectedSorts[0]?.label
                                    ? selectedSorts[0]?.label?.slice(0, 3) +
                                      ' ' +
                                      selectedSorts[0]?.subLabel
                                    : 'Sorter efter'}
                            </FilterToggler>
                        </FilterWrapper>
                    </>
                )}

                <CasinoPageContent
                    category={activeCategory}
                    sortedGames={displayedGames}
                    allGames={gamesToMap}
                    setShowLoader={(state) => setShowLoader(state)}
                />

                {showLoader && renderLoader()}

                {displayedGames.length === 0 &&
                    showNoGamesLabel() &&
                    Boolean(isGamesReceived) &&
                    !showLoader && (
                        <Text align={'center'} pt={3}>
                            Ingen match fundet.
                        </Text>
                    )}
            </StyledPageContent>
        </PageWrapper>
    )
}

export default withTheme(WithGames(CasinoPage))
