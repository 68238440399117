import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import SmallMultiSwitcher from 'ui/controls/SmallMultiSwitcher'
import UserAdapter from 'common/adapters/UserAdapter'
import Text from 'ui/Text'
import Loader from 'ui/Loader'
import Flex from 'ui/Flex'
import Box from 'ui/Box'
import TransactionList from 'features/UserHistory/Components/TransactionList'
import ChooseDate from 'features/UserHistory/Components/ChooseDate'
import Button from 'ui/controls/Button'
import { BACKGROUNDS, BREAKPOINTS } from 'themes'
import IntercomAdapter from 'common/adapters/IntercomAdapter'

const t = window.T

const limitIntervals = [
    {
        name: 'day',
        period: '1',
        title: t.transactions.day,
        groupBy: 'DD',
    },
    {
        name: 'week',
        period: '7',
        title: t.transactions.week,
        groupBy: 'DD',
    },
    {
        name: 'month',
        period: '30',
        title: t.transactions.month,
        groupBy: 'W',
        prefix: t.transactions.week.toUpperCase(),
    },
    {
        name: 'year',
        period: '365',
        title: t.transactions.year,
        groupBy: 'MMMM',
    },
]

const StyledButton = styled(Button)`
    position: fixed;
    bottom: 1.5rem;
    width: 200px;
    right: 100px;
    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        right: 50%;
        min-width: 250px;
        width: 50vw;
        transform: translateX(50%);
    }
`

const StyledLink = styled.span`
    color: ${BACKGROUNDS.blue};
    text-decoration: underline;
    cursor: pointer;
`

const PaymentHistory = ({ theme }) => {
    const [curTab, setCurTab] = useState(limitIntervals[0])
    const [beginDate, setBeginDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [transactionData, setTransactionData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [fromDate, setFromDate] = useState(
        moment()
            .subtract(1, 'month')
            .toDate()
    )
    const [toDate, setToDate] = useState(moment().toDate())
    const [showChooseDate, setShowChooseDate] = useState(false)
    const [periodTitle, setPeriodTitle] = useState([])

    const transactionDataFormat = 'DDMMYYYY'

    useEffect(() => {
        getTransactionData(
            moment()
                .subtract(curTab.period, 'd')
                .format(transactionDataFormat),
            moment().format(transactionDataFormat)
        )
    }, [])

    useEffect(
        () => {
            if (beginDate && endDate) {
                getTransactionData(beginDate, endDate)
                setPeriodTitle([])
            }
        },
        [beginDate, endDate]
    )

    const intercomClickHandler = () => {
        IntercomAdapter.show()
    }

    const changeTab = (tabName) => {
        if (tabName !== curTab.name) {
            setLoaded(false)
            const selectedTab = limitIntervals.filter(
                (tab) => tab.name === tabName
            )[0]
            setBeginDate(
                moment()
                    .subtract(selectedTab.period, 'd')
                    .format(transactionDataFormat)
            )
            setEndDate(moment().format(transactionDataFormat))
            setCurTab(selectedTab)
        }
    }
    const getTransactionData = (beginDate, endDate) => {
        UserAdapter.getTransactionList(beginDate, endDate)
            .then((res) => {
                if (res.data) {
                    setTransactionData(parseWithdraw(res.data))
                }
                setLoaded(true)
            })
            .catch((error) => {
                console.error('Get transaction data failed: ', error)
            })
    }

    const parseWithdraw = (items) => {
        return items.map((item) => {
            if (item.type === 0) {
                item.amount = -Math.abs(item.amount)
            }
            return item
        })
    }

    const getType = (data) => {
        let result = ''
        const format =
            data.type === 1
                ? t.userArea.userHistory.resultTable.deposit
                : t.userArea.userHistory.resultTable.withdraw
        switch (data.typeSub) {
            case 'T':
            case 'I':
            case 'B':
            case 'DB':
                result = t.userArea.userHistory.resultTable.bonus
                break
            case 'DI':
                result = format + t.userArea.userHistory.resultTable.dibs
                break
            case 'AP':
                result = format + t.userArea.userHistory.resultTable.apco
                break
            default:
                result = format
                break
        }
        return result
    }

    const showData = () => {
        setLoaded(false)
        setShowChooseDate(false)
        getTransactionData(
            moment(fromDate).format(transactionDataFormat),
            moment(toDate).format(transactionDataFormat)
        )
        setPeriodTitle([fromDate, toDate])
    }

    return (
        <>
            {showChooseDate ? (
                <ChooseDate
                    uiDateFormat={'DD MMM. YYYY'}
                    fromDate={fromDate}
                    toDate={toDate}
                    showData={showData}
                    closeDatePicker={() => setShowChooseDate(false)}
                    setFromDate={(data) => setFromDate(data)}
                    setToDate={(data) => setToDate(data)}
                />
            ) : (
                <>
                    <Box p={3}>
                        <SmallMultiSwitcher
                            optionList={limitIntervals}
                            value={curTab.name}
                            onChange={changeTab}
                            color={theme.dark}
                            activeColor={theme.dark}
                            activeBgColor={theme.light}
                        />
                    </Box>
                    <Text
                        style={{
                            margin: '20px auto',
                            padding: '0 30px',
                        }}
                    >
                        For en fuld transaktionsoversigt, eller en liste af
                        transaktioner for et givet tidsrum bedes du rette
                        henvendelse til vores{' '}
                        <StyledLink onClick={intercomClickHandler}>
                            kundeservice
                        </StyledLink>
                        .
                    </Text>
                    <Box
                        px={3}
                        height={'100%'}
                        style={{
                            overflowY: 'scroll',
                        }}
                    >
                        {!loaded ? (
                            <Flex mt={3}>
                                <Loader />
                            </Flex>
                        ) : transactionData.length ? (
                            <TransactionList
                                data={transactionData}
                                groupBy={curTab.groupBy}
                                prefix={curTab.prefix}
                                theme={theme}
                                getType={getType}
                                customTitle={fromDate}
                                periodTitle={periodTitle}
                                listType={'deposit'}
                            />
                        ) : (
                            <Text align={'center'} mt={4}>
                                {t.transactions.noData}
                            </Text>
                        )}
                    </Box>
                    <StyledButton
                        block
                        size={'lgB'}
                        mt={5}
                        width="100%"
                        shadowed
                        onClick={() => setShowChooseDate(true)}
                    >
                        {t.transactions.chooseDate}
                    </StyledButton>
                </>
            )}
        </>
    )
}

export default PaymentHistory
