const LIMIT_TYPE_DAILY = 'daily'
const LIMIT_TYPE_WEEKLY = 'weekly'
const LIMIT_TYPE_MONTHLY = 'monthly'

/**
 * @property {number}  dailyAmount         Current limit amount
 * @property {number}  dailyAmountChange   Since amount changes next day after its change was requested this prop stores value to update
 * @property {boolean} dailyAmountLimit    Determines whether deposit limit defined by user or not yet
 * @property {string}  dailyDate           Timestamp the deposit limit expires at
 * @property {number}  weeklyAmount        Current limit amount
 * @property {number}  weeklyAmountChange  Since amount changes next day after its change was requested this prop stores value to update
 * @property {boolean} weeklyAmountLimit   Determines whether deposit limit defined by user or not yet
 * @property {string}  weeklyDate          Timestamp the deposit limit expires at
 * @property {number}  monthlyAmount       Current limit amount
 * @property {number}  monthlyAmountChange Since amount changes next day after its change was requested this prop stores value to update
 * @property {boolean} monthlyAmountLimit  Determines whether deposit limit defined by user or not yet
 * @property {string}  monthlyDate         Timestamp the deposit limit expires at
 */
class DepositLimitDTO {
    /**
     * @param {Object} attributes           Attributes that will be imported to DTO instance as they are.
     *                                      @see class properties doc
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes)
    }
    /**
     * @param {object} serialized Raw response object.
     *                            @see propertyMap to investigate its shape
     */
    static unserialize(serialized) {
        return new DepositLimitDTO(serialized)
    }

    /**
     * Attributes with [Amount] postfix will be sent with values of [AmountChange]
     * attributes as server expects data to be received this way.
     *
     * @returns {Object} serialized object ready for sending to server side
     */
    serialize() {
        const dailyAmountChange = parseInt(this.dailyAmountChange, 10)
        const weeklyAmountChange = parseInt(this.weeklyAmountChange, 10)
        const monthlyAmountChange = parseInt(this.monthlyAmountChange, 10)

        return {
            dailyAmount: dailyAmountChange,
            dailyAmountLimit: this.dailyAmountLimit || false,

            weeklyAmount: weeklyAmountChange,
            weeklyAmountLimit: this.weeklyAmountLimit || false,

            monthlyAmount: monthlyAmountChange,
            monthlyAmountLimit: this.monthlyAmountLimit || false,

            maxDeposit: this.maxDeposit || -1,
        }
    }

    set(attributes) {
        return new DepositLimitDTO({ ...this, ...attributes })
    }

    changeAmount(newAmount, limitType) {
        return this.set({
            [limitType + 'AmountChange']: newAmount,
        })
    }
}

export default DepositLimitDTO

export { LIMIT_TYPE_DAILY, LIMIT_TYPE_WEEKLY, LIMIT_TYPE_MONTHLY }
