import React, { useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import Box from 'ui/Box'
import PaymentHistory from 'features/UserHistory/Components/PaymentHistory'
import PlayHistory from 'features/UserHistory/Components/PlayHistory'
import SmallMultiSwitcher from 'ui/controls/SmallMultiSwitcher'

const t = window.T

const ActionList = [
    {
        name: 'transactions',
        title: t.transactions.transactionHistory,
    },
    {
        name: 'bets',
        title: t.transactions.gameHistory,
    },
]

const UserHistory = ({ theme }) => {
    const [active, setActive] = useState('transactions')

    return (
        <Box
            background={theme.light}
            height={'100%'}
            style={{ overflow: 'hidden' }}
        >
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    Historik
                </Text>
            </ModalTopBar>

            <Box background={theme.primary} pb={3} px={3}>
                <SmallMultiSwitcher
                    onChange={(value) => setActive(value)}
                    value={active}
                    optionList={ActionList}
                    activeColor={theme.dark}
                    activeBgColor={theme.light}
                />
            </Box>

            <Box height={'100%'}>
                {active === 'transactions' ? (
                    <PaymentHistory theme={theme} />
                ) : (
                    <PlayHistory theme={theme} />
                )}
            </Box>
        </Box>
    )
}

export default UserHistory
