import React from 'react'
import styled from 'styled-components'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import Box from 'ui/Box'
import Flex from 'ui/Flex'

const t = window.T

const Amount = styled(Flex)`
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-end;
`

const Limits = ({ theme, ...props }) => {
    let limits = {
        daily: {
            current: props.dailyAmount,
            date: props.dailyDate,
            newLimit: props.dailyAmountChange,
        },
        weekly: {
            current: props.weeklyAmount,
            date: props.weeklyDate,
            newLimit: props.weeklyAmountChange,
        },
        monthly: {
            current: props.monthlyAmount,
            date: props.monthlyDate,
            newLimit: props.monthlyAmountChange,
        },
    }

    return (
        <Box>
            <ProfileCard
                borderRadiusTop
                title={t.userArea.depositLimit.currentLimit}
                background={theme.light}
                subtitleColor={theme.gray}
            >
                <Amount>{limits[props.curTab].current}</Amount>
            </ProfileCard>
            <ProfileCard
                borderRadiusBottom
                title={t.userArea.depositLimit.newLimit}
                background={theme.light}
                subtitleColor={theme.gray}
                subtitle={
                    limits[props.curTab].date ? limits[props.curTab].date : '-'
                }
            >
                <Amount>{limits[props.curTab].newLimit}</Amount>
            </ProfileCard>
        </Box>
    )
}

export default Limits
