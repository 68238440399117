import styled from 'styled-components'
import { COLORS, PALETTE } from '../themes'

const rotation = (props) => {
    switch (props.direction) {
        case 'right':
            return 0
        case 'left':
            return 180
        case 'up':
            return -90
        case 'down':
            return 90
        default:
            return 0
    }
}

const size = (props) => props.size || 5

const Arrow = styled.span`
    display: ${(props) => (!props.block ? 'inline-block' : 'block')};
    vertical-align: center;
    transform: rotate(${(props) => rotation(props)}deg);
    border-top: ${size}px solid transparent;
    border-bottom: ${size}px solid transparent;
    border-left: ${size}px solid
        ${(props) => props.color || PALETTE.primaryText};
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    width: 0px;
    height: 0px;
`

const ExpandableArrow = styled(Arrow)`
    transform: ${(props) => (props.expanded ? `rotate(90deg)` : `none`)};
    transition: all 400ms ease-out;
`

export default Arrow
export { ExpandableArrow }
