import React from 'react'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import styled from 'styled-components'
import Input from 'ui/controls/Input'
import Button from 'ui/controls/Button'
import PropTypes from 'prop-types'

const t = window.T

const StyledInput = styled(Input)`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

const ActionButton = styled(Button)``

const LimitForm = ({
    value = '',
    onChange = (f) => f,
    onSubmit = (f) => f,
    onCancel = (f) => f,
}) => {
    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13 && value) {
            e.preventDefault()
            onSubmit()
        }
    }

    return (
        <>
            <Flex mt={3}>
                <StyledInput
                    type="number"
                    align="center"
                    value={value}
                    hideCancel
                    placeholder={t.userArea.depositLimit.inputPlaceholder}
                    onChange={onChange}
                    rightText={
                        <Text as={'span'} bold>
                            {' '}
                            kr.
                        </Text>
                    }
                    onKeyPress={keyPressHandler}
                />
            </Flex>
            <Flex>
                <ActionButton
                    my={3}
                    mr={1}
                    size={'lgB'}
                    onClick={onCancel}
                    variant={'light'}
                    style={{ flex: '50%' }}
                >
                    {t.cancel}
                </ActionButton>
                <ActionButton
                    ml={1}
                    my={3}
                    size={'lgB'}
                    onClick={onSubmit}
                    variant={'danger'}
                    style={{ flex: '50%' }}
                    disabled={!value}
                >
                    {t.submit}
                </ActionButton>
            </Flex>
        </>
    )
}

LimitForm.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default LimitForm
