import styled, { css } from 'styled-components'
import Img from 'ui/Img'
import Box from 'ui/Box'
import React, { useEffect, useRef, useState } from 'react'
import Button from 'ui/controls/Button'
import { useHistory } from 'react-router-dom'
import {
    byPlatform,
    detectMobByScreen,
    detectMobile,
} from 'common/adapters/DeviceDetectAdapter'
import Icon from 'ui/Icon'
import arrowRight from 'icons/basic-icons/arrowRight.svg'
import { BACKGROUNDS, BREAKPOINTS } from 'themes'
import Container from 'ui/Container'
import UserAdapter from 'common/adapters/UserAdapter'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import ReactTooltip from 'react-tooltip'
import { modalService } from 'services/ModalService'
import GamesService from 'services/GamesService'
import infoIcon from 'icons/basic-icons/info.svg'
import FavoriteButton from 'features/FavoriteButton'
import { backDropStyle } from 'ui/StylesUtils'
import gamesService from 'services/GamesService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { CDN_IMG_PATH } from 'configs/main'

const t = window.T
const VIDEO_PATH = `${CDN_IMG_PATH}videos`

const HighlightWrapper = styled(Box)`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: ${(props) =>
        props.background ? props.background : BACKGROUNDS.white};

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        height: auto;

        &::after {
            content: '';
        }
    }
`
const HighlightTooltip = styled(ReactTooltip)`
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
`

const StyledVideo = styled.video`
    &::-webkit-media-controls-start-playback-button {
        display: none;
    }
`
const StyledImg = styled(Img)`
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
`

const ButtonArea = styled.div`
    ${(props) =>
        props.desktop &&
        css`
            display: flex;
            z-index: 2;
            position: absolute;
            color: ${props.theme.light};
            text-shadow: 3px 3px 5px #000000;
            left: 10%;
            bottom: 21%;
        `};

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        left: 20px;
        bottom: ${(props) => (props.extraBottomSpace ? '80px' : '30px')};
    }

    @media screen and (max-width: ${BREAKPOINTS.xl + 'px'}) {
        bottom: 75px;
    }

    button {
        margin: 0 12px 0 0;
    }
`

const TransButton = styled(Button)`
    ${backDropStyle};
    color: #fff;
    svg {
        vertical-align: unset;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.3);
    }
`

const ContentHolder = styled.div`
    padding-top: ${detectMobByScreen() ? '42.86%' : '31.25%'};
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
`

const IframeCover = styled.div`
    top: 0;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 2;
`

const Rules = styled.div`
    width: ${({ desktop }) => (desktop ? 'auto' : '100%')};
    padding: ${({ desktop }) => (desktop ? '0.75rem 1rem' : '1rem')};
    position: ${({ desktop }) => (desktop ? 'absolute' : 'relative')};
    font-size: 12px;
    line-height: 15px;
    height: ${({ desktop }) => (desktop ? '58px' : '105px')};
    display: flex;
    align-items: center;
    bottom: ${({ desktop }) => (desktop ? '10px' : '0')};
    box-sizing: border-box;
    color: #fff;
    text-align: left;
    z-index: 3;
    left: ${({ desktop }) => (desktop ? '10%' : '0')};
    right: ${({ desktop }) => (desktop ? '10%' : '0')};
    border-radius: ${({ desktop }) => (desktop ? '8px' : 'none')};

    ${Container} {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${({ desktop }) => (desktop ? 3 : 6)};
        -webkit-box-orient: vertical;
    }

    ${({ desktop }) =>
        desktop
            ? css`
                  ${backDropStyle};
              `
            : css`
                  font-size: 10px;
                  line-height: 15px;
                  background: #f5f7fc;
                  color: ${(props) => props.theme.dark};
                  padding-top: 0;

                  ${Container} {
                      padding-top: 0.5rem;
                  }
              `};
    p {
        margin: 0;
        padding: 0;
    }
`

const HighlightCard = ({
    src,
    videoUrl,
    desktop = false,
    gameId = '',
    seoUrl,
    showBtnArea = true,
    loopVideo = true, //0 or 1
    rules = '',
    isBackSlide = false,
    gameClickCallback = (game) => {},
}) => {
    const [game, setGame] = useState({})
    const [isFavorite, setIsFavorite] = useState(false)
    const history = useHistory()
    const childRef = useRef()
    const ref = useRef(null)

    const readMoreHandler = () => {
        history.push(seoUrl)
    }

    const handleOnClickFavorite = (event) => {
        event.preventDefault()
        event.stopPropagation()
        childRef?.current?.toggleFavorite(event)
    }

    const gameInfoModal = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const game = GamesService.getGameById(gameId)

        ReactTooltip.hide()
        modalService.showModal('GAME_INFO', {
            game,
            gameClickCallback: () => gameClickCallback(game),
            initialProps: {
                animation: true,
                outsideClose: true,
            },
        })
    }

    const contentClickHandler = () => {
        if (!desktop && seoUrl) {
            readMoreHandler()
        } else if (!desktop && gameId) {
            gameClickCallback(game)
        } else {
            return null
        }
    }

    const videoSettings = () => {
        const settings = {
            controls: 0,
            autopause: false,
            autoplay: true,
            muted: 1,
            loop: Number(loopVideo),
        }

        return new URLSearchParams(settings).toString()
    }

    const TooltipProps = () =>
        !detectMobile()
            ? {
                  'data-place': 'bottom',
                  'data-type': 'light',
                  'data-effect': 'solid',
                  'data-class': 'tooltip',
                  'data-for': 'highlight-tooltip',
                  'data-offset': "{'bottom': 10}",
                  onMouseOver: () => {
                      ReactTooltip.rebuild()
                  },
              }
            : null

    useEffect(
        () => {
            const gameData = GamesService.getGameById(gameId)

            if (gameData) {
                setGame(gameData)
            }
        },
        [gameId]
    )

    useEffect(
        () => {
            if (ref && ref.current) {
                ref.current.currentTime = '0'
            }
        },
        [isBackSlide]
    )

    return (
        <HighlightWrapper desktop={desktop}>
            <ContentHolder onClick={() => contentClickHandler()}>
                {videoUrl ? (
                    <>
                        <StyledVideo
                            controlsList="nofullscreen"
                            controls={false}
                            playsInline={true}
                            autoPlay={true}
                            loop={true}
                            ref={ref}
                            muted={true}
                            poster={src}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                aspectRatio: desktop ? '16/9' : '7/4',
                                zIndex: 1,
                            }}
                        >
                            <source
                                src={VIDEO_PATH + videoUrl}
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </StyledVideo>

                        <IframeCover />
                    </>
                ) : (
                    <StyledImg width={'100%'} src={src} desktop={desktop} />
                )}
            </ContentHolder>
            {desktop && (
                <ButtonArea
                    width={'100%'}
                    desktop={desktop}
                    extraBottomSpace={rules}
                >
                    {Object.keys(game).length ? (
                        <Button
                            block={!desktop}
                            onClick={() => gameClickCallback(game)}
                        >
                            <Icon
                                src={arrowRight}
                                size={'md'}
                                style={{ margin: '0 8px 0', float: 'left' }}
                            />
                            {t.startGame}
                        </Button>
                    ) : null}
                    {seoUrl && (
                        <TransButton onClick={readMoreHandler}>
                            {t.readMore}
                        </TransButton>
                    )}
                    {Object.keys(game).length ? (
                        <>
                            <TransButton
                                onClick={gameInfoModal}
                                data-tip={'Vis spilinfo'}
                                {...TooltipProps()}
                            >
                                <Icon
                                    src={infoIcon}
                                    size={'sm'}
                                    color={'white'}
                                    style={{ verticalAlign: 'unset' }}
                                />
                            </TransButton>
                            <TransButton
                                data-tip={
                                    isFavorite
                                        ? t.favorite.remove
                                        : t.favorite.add
                                }
                                onClick={handleOnClickFavorite}
                                {...TooltipProps()}
                            >
                                <FavoriteButton
                                    game={game}
                                    ref={childRef}
                                    setFavorite={setIsFavorite}
                                />
                            </TransButton>
                        </>
                    ) : null}
                </ButtonArea>
            )}
            {rules && (
                <Rules desktop={desktop}>
                    <Container
                        maxWidth={'1124px'}
                        dangerouslySetInnerHTML={{
                            __html: rules,
                        }}
                    />
                </Rules>
            )}
            <HighlightTooltip id={'highlight-tooltip'} />
        </HighlightWrapper>
    )
}

export default HighlightCard
