import { BehaviorSubject, combineLatest } from 'rxjs'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { makeRequest } from 'utils/REST'
import { byPlatform, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import {
    getPinLogin,
    removePinLogin,
    setPinLogin,
} from 'services/PinLoginService'
import { modalService } from 'services/ModalService'
import { CONTENT_API_BASE_URL } from 'configs/rest'
import { ENV } from 'configs/main'
import GamesUtilsService from 'services/GamesUtilsService'

const user = new BehaviorSubject({})
const profile = new BehaviorSubject({})
const isUserLoggedIn = new BehaviorSubject(false)
const clientId = new BehaviorSubject(null)

const clearUserState = () => {
    user.next({})
    profile.next({})
    isUserLoggedIn.next(false)
    clientId.next(0)
}

const attachUser = () => {
    UserAdapter.getProfile()
        .then((profileData) => {
            profile.next(profileData)
            UserAdapter.attachUserToDevice(profileData)
            UserAdapter.setLastLoginDate(profileData)

            if (profileData && profileData.userId) {
                Promise.resolve(
                    IntercomAdapter.exec('update', {
                        name:
                            profileData.firstName + ' ' + profileData.lastName,
                        user_id: profileData.userId,
                        phone: profileData.phoneNr,
                        postal_code: profileData.zip,
                        email: profileData.email,
                        username: profileData.userName,
                        last_active_on: byPlatform('rod25_mob', 'rod25_web'),
                        last_login_rod25_at: Math.round(
                            profileData.extraInfo.loginTime / 1000
                        ),
                    })
                )
            }
        })
        .catch((error) => {
            profile.next({})
            NotificationConductor.error(error.toString())
        })
}

const checkUserLogin = () => {
    return UserAdapter.isUserLoggedIn()
        .then((userData) => {
            isUserLoggedIn.next(!!userData)
            if (userData) {
                user.next(userData)

                return { login: true }
            } else {
                clearUserState()
                localStorage.setItem('logged', 'false')
                return { login: false }
            }
        })
        .catch((error) => {
            clearUserState()
            localStorage.setItem('logged', 'false')
            NotificationConductor.error(
                //'Something went wrong, please, try again later'
                error
            )

            return { login: false }
        })
}

const setIsUserLoggedIn = (cb = (f) => f) => {
    UserAdapter.isUserLoggedIn()
        .then((userData) => {
            if (userData) {
                cb(userData)
                UserAdapter.fetchHash().then(({ data }) => {
                    if (!!data) {
                        setPinLogin(data + '|' + userData.userId)
                    } else {
                        removePinLogin()
                    }
                })
                user.next(userData)
                isUserLoggedIn.next(!!userData)
                localStorage.setItem('userName', userData.userName)
                localStorage.setItem('userId', userData.userId)
                localStorage.setItem('logged', 'true')
            } else {
                clearUserState()
                localStorage.setItem('logged', 'false')
            }
        })
        .catch((error) => {
            clearUserState()
            localStorage.setItem('logged', 'false')

            NotificationConductor.error(
                'Something went wrong, please, try again later'
            )
        })
}

const nemIdLogin = (result, content) => {
    UserAdapter.nemIdLogin(result, content)
        .then((user) => {
            user.next(user)
            localStorage.setItem('logged', 'true')

            window.dataLayer.push({ event: 'login' })

            UserAdapter.getProfile()
                .then((userProfile) => {
                    profile.next(userProfile)
                })
                .catch((error) => {
                    NotificationConductor.error(error.toString())
                })
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
        })
}

const logOut = () => {
    UserAdapter.logout()
        .then((res) => {
            isUserLoggedIn.next(false)
            localStorage.setItem('logged', 'false')
            localStorage.setItem('userId', 0)
            IntercomAdapter.exec('boot', { hide_default_launcher: true })
            IntercomAdapter.shutdown()
            GamesUtilsService.fetchFavorite()
            clearUserState()
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
        })
}

const getUserProfile = () =>
    combineLatest(user, profile, (v1, v2) => ({
        ...v1,
        ...v2,
    }))

const getUser = () => {
    return user
}

const getMinDeposit = () => {
    return makeRequest(
        'get',
        `${CONTENT_API_BASE_URL}/api/DepositsConfig.php?r=GetDepositConfig&env=${ENV()}&product=roed`
    ).then((response) => {
        return response.json()
    })
}

const openLoginModal = () => {
    const pinHash = localStorage.getItem('pinHash')
    const userName = localStorage.getItem('userName')
    if (detectMobile()) {
        pinHash && userName
            ? modalService.showModal('PIN_LOGIN', {
                  action: 'check',
                  noBack: true,
                  initialProps: { modalTopMargin: '0' },
              })
            : modalService.showModal('LOGIN')
    } else {
        modalService.showModal('LOGIN', {
            noBack: true,
            noClose: false,
        })
    }
}

const showPinLoginPopup = () => {
    if (detectMobile()) {
        const pinLoginStatus = localStorage.getItem('pinPopup')
        if ((!pinLoginStatus || pinLoginStatus === 'false') && !getPinLogin()) {
            modalService.showModal('PIN_LOGIN_POPUP', {
                initialProps: { animation: true, fullSize: true },
            })
        } else {
            modalService.closeModal()
        }
    }
}

export default {
    attachUser,
    getMinDeposit,
    setIsUserLoggedIn,
    nemIdLogin,
    logOut,
    clearUserState,
    getUser,
    getUserProfile,
    checkUserLogin,
    openLoginModal,
    showPinLoginPopup,
    getUserLoggedStatus: () => isUserLoggedIn.asObservable(),
    setClientId: (id) => clientId.next(id),
}
