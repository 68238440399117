import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import NewSignUp from '@it25syv/sign-up'
import { modalService } from 'services/ModalService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'

const SignUpWrapper = styled.section`
    height: 100%;
    background-color: white;
    overflow: auto;
    padding: 15px;
`

const SignUp = () => {
    const scrollRef = useRef(null)

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'red25.clickToSignup',
            })
        }
    }, [])
    return (
        <SignUpWrapper ref={scrollRef}>
            <NewSignUp
                defaultStep={1}
                onEmailExists={() => {
                    modalService.showModal('EMAIL_EXIST', {
                        initialProps: { modalTopMargin: '0' },
                    })
                }}
                showError={(error) => NotificationConductor.error(error)}
                onClose={() => {
                    modalService.closeModal()
                }}
                scrollableWrapperRef={scrollRef}
                project="rod25"
            />
        </SignUpWrapper>
    )
}

export default SignUp
