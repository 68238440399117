import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import NewSignUp from '@it25syv/sign-up'
import { modalService } from 'services/ModalService'
import NotificationConductor from 'common/conductors/NotificationConductor'

const SignUpWrapper = styled.section`
    height: 100%;
    position: absolute;
    right: 0;
    overflow: auto;
    width: 400px;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 15px;
`

const SignUp = () => {
    const scrollRef = useRef(null)

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'red25.clickToSignup',
            })
        }
    }, [])

    return (
        <SignUpWrapper ref={scrollRef}>
            <NewSignUp
                defaultStep={1}
                onEmailExists={() => {
                    modalService.showModal('EMAIL_EXIST', {
                        initialProps: { modalTopMargin: '0' },
                    })
                }}
                showError={(error) => NotificationConductor.error(error)}
                onClose={() => {
                    window.history.pushState('', '', '/')
                    modalService.closeModal()
                }}
                scrollableWrapperRef={scrollRef}
                project="rod25"
            />
        </SignUpWrapper>
    )
}

export default SignUp
