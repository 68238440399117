import React, { useEffect, useState } from 'react'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
import Text from 'ui/Text'
import TransactionList from 'features/UserHistory/Components/TransactionList'
import Box from 'ui/Box'

const t = window.T

const PlayHistory = ({ theme }) => {
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let loadTimeOut = null
        setLoading(true)
        UserAdapter.getTransactionExternalList(0, 100) // 0 - 100, because mobile team use it ¯\_(ツ)_/¯
            .then((res) => {
                if (res.data) {
                    setTransactions(res.data)
                }
            })
            .catch((error) => {
                NotificationConductor.error(error)
            })
            .finally(() => {
                loadTimeOut = setTimeout(() => setLoading(false), 200)
            })
        return () => {
            clearTimeout(loadTimeOut)
        }
    }, [])

    const getType = (data) => {
        let result = ''
        const deposit = data.typeSub === 'W'
        switch (data.text) {
            case 'CM':
            case 'NS':
            case 'NP':
                result = 'NetEnt'
                break
            case 'EP':
            case 'ES':
                result = 'Evolution'
                break
            case 'GP':
            case 'GS':
                result = 'Gamblify'
                break
            case 'IP':
            case 'IS':
                result = 'iSoftBet'
                break
            case 'PP':
            case 'PS':
                result = 'PlaynGo'
                break
            case 'SP':
            case 'SS':
                result = 'Spigo'
                break
            case 'SA':
            case 'SW':
                result = 'SGDigital'
                break
            case 'YP':
            case 'YS':
                result = 'Yggdrasil'
                break
            case 'RS':
            case 'RP':
                result = 'Red Tiger'
                break
            case 'AS':
            case 'AP':
                result = 'Pragmatic Play'
                break
            default:
                result = deposit ? 'Gevinst' : 'Indsats'
                break
        }
        return result
    }

    return (
        <Box
            p={3}
            height={'100%'}
            style={{
                overflowY: 'scroll',
            }}
        >
            {loading ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : (
                <TransactionList
                    getType={getType}
                    theme={theme}
                    data={transactions}
                />
            )}
            {!loading && transactions.length === 0 ? (
                <Text align={'center'}>{t.transactions.noData}</Text>
            ) : null}
        </Box>
    )
}

export default PlayHistory
