import Slider from 'features/Carousel/Slider'
import {
    detectMobByScreen,
    detectMobile,
} from 'common/adapters/DeviceDetectAdapter'
import Flex from 'ui/Flex'
import React, { Fragment, useEffect, useState } from 'react'
import HighlightCard from 'features/Highlight/HighlightCard'
import Spinner from 'ui/Spinner'
import { useRouteMatch } from 'react-router-dom'
import { CASINO_CATEGORIES, HIGHLIGHTS_CATEGORIES } from 'configs/categories'
import ContentService from 'services/ContentService'
import { BREAKPOINTS } from 'themes'
import ModalsMap from 'configs/modals'
import withGames from 'common/Hocs/WithGames'

const HighlightSlider = ({
    theme,
    loop = true,
    gameStartHandler,
    allProvGames,
}) => {
    const [loading, setLoading] = useState(true)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [highlights, setHighlights] = useState([])
    const [isBackSlide, setIsBackSlide] = useState(false)
    const match = useRouteMatch()

    const HighlightCategories = [...HIGHLIGHTS_CATEGORIES, ...CASINO_CATEGORIES]

    const calculateSlideParams = (current, next) => {
        const back = next > currentSlide

        setIsBackSlide(back <= 0)
        setCurrentSlide(next)
    }

    const getCategory = (category) =>
        HighlightCategories.find((c) => c.url === category)

    const showPageCategory = () => {
        let category = ''
        const getPath = match.params?.category
            ? match.params.category
            : match.url

        const findModal = ModalsMap.find((c) => c.url === getPath)

        if (getCategory(getPath)?.showpage) {
            category = getCategory(getPath)?.showpage
        } else if (findModal?.name) {
            //if url === to modal, show homepage
            category = 'homepage'
        }

        return category
    }

    useEffect(() => {
        ContentService.setCampaignsList()
    }, [])

    useEffect(
        () => {
            setLoading(false)
        },
        [allProvGames]
    )

    useEffect(
        () => {
            const sub = ContentService.getCampaignsList().subscribe((res) => {
                if (res.length) {
                    const highlights = res.filter(
                        (el) =>
                            el.highlight === 'true' &&
                            el.active === 'true' &&
                            el.showpage.split('|').indexOf(showPageCategory()) > //set default highlight page
                                -1
                    )
                    setHighlights(highlights)
                }
            })

            return () => {
                sub.unsubscribe()
            }
        },
        [match.url]
    )

    return (
        <Fragment>
            {loading ? (
                <Flex>
                    <Spinner color={theme.darkGray} size={'xl'} m={3} />
                </Flex>
            ) : highlights[0] ? (
                <Slider
                    arrows={detectMobByScreen(BREAKPOINTS.lg) ? false : true}
                    loop={loop}
                    dots={true}
                    withBottomBar={
                        detectMobile()
                            ? highlights.length &&
                              highlights[currentSlide]?.rules
                            : false
                    }
                    autoChangeTime={10000}
                    onChange={calculateSlideParams}
                    customStyle={{
                        padding: detectMobile() ? '0' : '0 0 16px',
                    }}
                >
                    {highlights.map(
                        (highlight, index) =>
                            highlight && (
                                <div key={index}>
                                    <HighlightCard
                                        index={index}
                                        id={'slide'}
                                        color={theme.light}
                                        src={
                                            highlight.bgimage
                                                ? detectMobByScreen()
                                                    ? highlight.bgimage_mob
                                                    : highlight.bgimage
                                                : ''
                                        }
                                        videoUrl={
                                            detectMobByScreen()
                                                ? highlight.vimeomob
                                                : highlight.vimeo
                                        }
                                        headline={highlight.title}
                                        seoUrl={
                                            highlight.seo_url === '/'
                                                ? ''
                                                : '/25nyt/' + highlight.seo_url
                                        }
                                        description={highlight.teaser}
                                        desktop={!detectMobByScreen()}
                                        gameId={
                                            highlight.game_id_mob ||
                                            highlight.game_id
                                                ? detectMobByScreen()
                                                    ? highlight.game_id_mob
                                                    : highlight.game_id
                                                : ''
                                        }
                                        externalUrl={highlight.external_url}
                                        externalBtnText={
                                            detectMobByScreen()
                                                ? highlight.button_text_mob
                                                : highlight.button_text
                                        }
                                        rules={highlight.rules}
                                        gameClickCallback={gameStartHandler}
                                        isBackSlide={isBackSlide}
                                    />
                                </div>
                            )
                    )}
                </Slider>
            ) : null}
        </Fragment>
    )
}

export default withGames(HighlightSlider)
