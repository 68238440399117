import React from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import { useHistory } from 'react-router-dom'
import { modalService } from 'services/ModalService'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import cross from 'icons/basic-icons/cross.svg'
import Icon from 'ui/Icon'

const Wrapper = styled.div`
    height: auto;
    width: 90%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(255, 255, 255);
    color: rgb(51, 51, 51);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 10px;
    transform: translateY(-50%);
    padding: 40px;
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: 12;
`
const Title = styled.h3`
    font-size: 24px;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
`
const CloseButton = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 5px;
    color: #fff;
    background: #c1c1c1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all 0.3s ease 0s;
    cursor: pointer;

    &:hover {
        background: #585858;
    }
    svg {
        height: 10px;
        width: 10px;
        line-height: 25px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`
const Description = styled.p`
    font-family: 'Roboto Condensed', sans-serif;
    flex: 1 1 auto;
    width: auto;
    font-size: 14px;
    padding: 10px 0;
    color: rgb(105, 105, 105);
    text-align: left;
    font-weight: normal;
    text-decoration: none;
    margin: 19px 0;
`

const ButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled(Button)`
    width: 47%;
    padding: 17px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
`

const EmailAlreadyExist = () => {
    const onLoginClick = () => {
        modalService.showModal('LOGIN', {
            props: { closeBefore: ['EMAIL_EXIST', 'SIGN_UP'] },
            initialProps: { modalTopMargin: '0' },
        })
    }

    return (
        <Wrapper closeBtn>
            <Title>Velkommen tilbage!</Title>
            <CloseButton
                onClick={() => {
                    modalService.closeModal('EMAIL_EXIST')
                }}
            >
                <Icon size={'sm'} src={cross} color={'white'} />
            </CloseButton>
            <Description>
                Din email er allerede registreret og din konto ligger klar til
                brug. Har du glemt dit kodeord anbefaler vi, at du logger ind
                med MitID eller kontakter kundeservice.
            </Description>

            <ButtonsArea>
                <StyledButton
                    background={'#ececf4'}
                    hover={'#f6f6fe'}
                    bold
                    size="lg"
                    onClick={() => {
                        modalService.closeModal()
                        IntercomAdapter.show()
                    }}
                >
                    Kontakt os
                </StyledButton>
                <StyledButton size="lg" bold onClick={onLoginClick}>
                    Log ind
                </StyledButton>
            </ButtonsArea>
        </Wrapper>
    )
}

export default EmailAlreadyExist
